@keyframes fallWithGravityAndRotate {
    0% {
        transform: translateX(0) rotate(0deg);
    }
    100% {
        animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
        transform: translateX(-500px) rotate(-360deg);
        opacity: 0;
    }
}
@keyframes acceptPieceAnimation {
    0% {
        transform: translateX(0) rotate(0deg);
    }

    100% {
        transform: translateX(15px) translateY(98px) rotate(26deg);

    }
}
#rejectPiece.animate-reject {
    animation: fallWithGravityAndRotate 3s linear infinite;
}
#rejectPiece.reject-hover {
    animation: fallWithGravityAndRotate 3s linear infinite;
}

@keyframes moveAlongCircle {
    0% {
        offset-distance: 0%;
    }
    100% {
        offset-distance: 100%;
    }
}

.circle {
    width: 50px;
    height: 50px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    offset-path: path('M150 200 a 50 50 0 1 1 100 0 a 50 50 0 1 1 -100 0');
    animation: moveAlongCircle 5s linear infinite;
}
#acceptPiece {
    z-index: 1000;
}

#acceptPiece.animate-accept {
    animation: acceptPieceAnimation 2s linear infinite;
}
#acceptPiece.accept-hover {
    animation: acceptPieceAnimation 2s linear infinite;
}
#acceptPuzzle {

}

.isMobile {
    /*overflow: hidden; !* Hides the scrollbars *!*/
}

#puzzleCanvas {

}
html {
    background-color: black;
}

