footer {
  background-color: #000;
  color: #fff;
  padding: 3.75rem 0;
  /*position: sticky;*/
  /*bottom: 0;*/
  /*margin-top: -1px;*/
}

.footer-address-wrapper {
  max-width: 456px;
  margin-top: 3rem;
}

@media (min-width: 992px) {
  .footer-address-wrapper {
    margin-top: 0;
  }
}

.footer-logo {
  margin-top: 1.5rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.footer-links a,
.footer-links button {
  text-align: left;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.footer-links a:hover,
.footer-links button:hover {
  color: #ba61ff;
}

.footer-contact {
  margin-bottom: 3rem;
  font-weight: 700;
  margin-top: 1rem;
}

.footer-contact br {
  display: none;
}

.footer-contact a {
  display: block;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 16px;
}

.footer-contact a.purple {
  color: #ba61ff;
}

.footer-contact a.purple:hover {
  color: #fff;
}

.footer-contact a:hover {
  color: #ba61ff;
}

.grid-12 {
  display: grid;
}

@media (min-width: 992px) {
  .grid-12 {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }

  .grid-12 .col-1to2 {
    grid-area: 1/1/2/3;
  }

  .grid-12 .col-1to4 {
    grid-area: 1/1/2/5;
  }

  .grid-12 .col-1to5 {
    grid-area: 1/1/2/6;
  }

  .grid-12 .col-1to6 {
    grid-area: 1/1/2/7;
  }

  .grid-12 .col-2to5 {
    grid-area: 1/2/2/6;
  }

  .grid-12 .col-2to11 {
    grid-area: 1/2/2/12;
  }

  .grid-12 .col-4to9 {
    grid-area: 1/4/2/10;
  }

  .grid-12 .col-7to11 {
    grid-area: 1/7/2/12;
  }

  .grid-12 .col-7to12 {
    grid-area: 1/7/2/13;
  }

  .grid-12 .col-8to11 {
    grid-area: 1/8/2/12;
  }

  .grid-12 .col-8to12 {
    grid-area: 1/8/2/13;
  }
}

.grid-12.with-gap {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-6-6 {
  display: grid;
}

@media (min-width: 768px) {
  .grid-6-6.tablet-6-6 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

@media (min-width: 992px) {
  .grid-6-6 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.grid-6-6.with-gap {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-6-6-mob {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.grid-6-6-mob.with-gap {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-4-4-4 {
  display: grid;
}

@media (min-width: 992px) {
  .grid-4-4-4 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.grid-4-4-4.with-gap {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-team {
  display: grid;
}

@media (min-width: 768px) {
  .grid-team {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

@media (min-width: 992px) {
  .grid-team {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

@media (max-width: 992px) {
  .order-2-mob {
    grid-row: 2;
  }
}

@media (max-width: 992px) {
  .order-1-mob {
    grid-row: 1;
  }
}
