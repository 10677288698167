

@keyframes arrow-down {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes arrow-right {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}
#animateArrow {
    animation: arrow-down 1.5s ease-in-out infinite;
}
@media screen and (max-width: 640px) {
    #desktopHome {
        display: none;
    }
    #mobileHome {
        display: block;
    }
}
@media screen and (min-width: 641px) {
    #desktopHome {
        display: block;
    }
    #mobileHome {
        display: none;
    }
}

#animateArrowHorizontal {
    animation: arrow-right 1.5s ease-in-out infinite;
}

.b-purple {
    color: #ba61ff;
}
.isMobile {
    /*transform-origin: top left; !* Sets the origin of transformation *!*/
    /*transform: rotate(90deg); !* Rotates the div and moves it into full view *!*/

}
.isMobile > div {
    /*transform: translateX(100px);*/
    /*poslition: fixed; !* Fixed or absolute positioning can take the div out of the normal flow *!*/
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100vh; !* Viewport height becomes the width *!*/
    /*height: 100vw; !* Viewport width becomes the height *!*/
    /*!* Depending on the direction of rotation, you might need to adjust the translate value *!*/
    /*background: lightblue; !* Just to make the div visible *!*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*text-align: center;*/
}
